<template>
  <div
    v-if="pagination.pages > 1"
    :class="classes.wrapper"
  >
    <button
      v-if="(xlAndLarger && (pagination.currentPage >= 5 || (pagination.pages < 5 && pagination.currentPage >= 2))) || (!xlAndLarger && pagination.currentPage == pagination.pages)"
      :class="[classes.arrow, classes.arrowLeft]"
      type="button"
      @click="changePage(1)"
    >
      В начало
    </button>
    <button
      v-if="!xlAndLarger && pagination.currentPage !== pagination.pages"
      :class="[classes.arrow, classes.arrowRight]"
      type="button"
      @click="changePage(pagination.currentPage + 1)"
    >
      Дальше
    </button>
    <ul :class="classes.pages">
      <template
        v-for="page in pagination.pages"
      >
        <li
          v-if="isPage(page) || isDots(page)"
          :key="page"
          :class="classes.page"
        >
          <button
            v-if="isPage(page)"
            :class="[classes.link, page == pagination.currentPage ? classes.linkActive : '']"
            type="button"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
          <button
            v-else-if="isDots(page)"
            :class="[classes.link, classes.linkDisabled]"
            type="button"
            disabled
          >
            ...
          </button>
        </li>
      </template>
    </ul>
    <button
      v-if="xlAndLarger && pagination.currentPage !== pagination.pages"
      :class="[classes.arrow, classes.arrowRight]"
      type="button"
      @click="changePage(pagination.currentPage + 1)"
    >
      Дальше
    </button>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { type PropType, computed } from 'vue'
import type { PaginatedMeta } from '~/types/models'

const props = defineProps({
  meta: {
    type: Object as PropType<PaginatedMeta>,
    default() { return {} },
    required: true,
  },
})

const classes = {
  wrapper: cx([
    'flex',
    'items-center',
    'flex-col',
    'max-w-[328px]',
    'my-0',
    'xl:flex-row',
    'xl:max-w-none',
  ]),
  arrow: cx([
    'w-full',
    'py-[9px]',
    'px-4',
    'rounded-xl',
    'bg-bg-main-thirdly',
    'text-[14px]/[100%]',
    'font-semibold',
    'text-accent',
    'whitespace-nowrap',
    'transition',
    'duration-300',
    'xl:w-auto',
    'mb-4',
    'xl:mb-0',
    'hover:text-primary-on-color',
    'hover:bg-bg-blue-accent-hover',
    'active:bg-bg-blue-accent-click',
  ]),
  arrowLeft: 'xl:mr-4',
  arrowRight: 'xl:ml-4',
  pages: 'flex',
  page: cx(['mr-2', 'last:mr-0']),
  link: cx([
    'flex',
    'justify-center',
    'items-center',
    'w-[32px]',
    'h-[32px]',
    'mr-2',
    'rounded',
    'text-ghost',
    'bg-none',
    'transition',
    'duration-300',
    'last:mr-0',
    'hover:bg-bg-main-thirdly',
  ]),
  linkActive: cx(['!text-primary-on-color', '!bg-bg-blue-accent']),
  linkDisabled: cx(['cursor-not-allowed', '!bg-none', '!text-ghost']),
  linkDots: cx(['p-0', '!w-[10px]']),
}

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const pagination = computed(() => {
  return {
    currentPage: props.meta['current_page'],
    pages: props.meta['last_page'],
  }
})

const emit = defineEmits(['changePage'])

const changePage = (page: number) => emit('changePage', page)

const isPage = (page: number) => {
  return (
    (pagination.value.currentPage <= 4 && page <= 4)
    || (pagination.value.currentPage <= 4
      && page <= (xlAndLarger.value ? 11 : 6))
      || (pagination.value.pages - pagination.value.currentPage
        < (xlAndLarger.value ? 7 : 2)
        && page > pagination.value.pages - (xlAndLarger.value ? 9 : 4))
        || (pagination.value.currentPage >= 5
          && (page == pagination.value.currentPage - 1
            || (xlAndLarger.value && page == pagination.value.currentPage - 2))) // сдвиг слева
            || page == pagination.value.currentPage // текущая страница
            || (pagination.value.currentPage >= 5
              && page - pagination.value.currentPage >= 1
              && page - pagination.value.currentPage <= (xlAndLarger.value ? 6 : 2)) // сдвиг справа
              || (!xlAndLarger.value && page == 1)
  ) // первая страница для мобилок
}
const isDots = (page: number) => {
  return (
    (pagination.value.currentPage <= 4
      && page >= (xlAndLarger.value ? 11 : 5)
      && page <= (xlAndLarger.value ? 1 : 6))
      || (pagination.value.currentPage >= 5
        && pagination.value.currentPage - page == (xlAndLarger.value ? 3 : 2)) // сдвиг слева
        || (!xlAndLarger.value
          && pagination.value.currentPage >= 5
          && pagination.value.pages - pagination.value.currentPage <= 2
          && pagination.value.pages - page == 4) // сдвиг слева, когда кончились элементы
          || (xlAndLarger.value
            && pagination.value.pages - pagination.value.currentPage < 7
            && page >= pagination.value.pages - 9) // сдвиг справа
            || (xlAndLarger.value
              && pagination.value.pages - pagination.value.currentPage >= 7
              && page >= pagination.value.pages)
  ) // сдвиг справа, когда кончились элементы
}
</script>
